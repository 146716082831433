import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import logo from 'images/logo_footer.png';
import { Link } from "react-router-dom";
import PCI from "images/certificates/pci.png"
import DISCOVER from "images/certificates/discover.png"
import visa from "images/certificates/VISA.png"
import express from "images/certificates/express.png"
import IATA from "images/certificates/IATA.png"
import PayPal from "images/certificates/PayPal.png"
import MasterCard from "images/certificates/MasterCard.png"
import './footer.css'
import { TiContacts } from "react-icons/ti";
import { AiOutlineMail } from "react-icons/ai";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { gtag_report_conversion } from "googleAnalytics/googleAds";



export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}


const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "",
    menus: [
      { href: "/about", label: "About Us" },
      { href: "/contact", label: "Contact Us" },
      { href: "/flights", label: "Flights" },
      { href: "/blogs", label: "Blogs" },
    ],
  },
  {
    id: "4",
    title: "Legal",
    menus: [
      { href: "/policy/privacy-policy", label: "Privacy Policy" },
      { href: "/policy/terms-and-conditions", label: "Terms & Conditions" },
      { href: "/policy/cookie-policy", label: "Cookies Policy" },
      { href: "/policy/cancellation-exchange-&-refund-policy", label: "Cancellation, Exchange & Refund Policy" },
      { href: "/policy/baggage-policy", label: "Baggage Policy & Fee" },
    ],
  },
  {
    id: "2",
    title: "Quick Links",
    menus: [
      { href: "/policy/dicounts-&-offers", label: "Discounts & Offers" },
    ],
  },
];

const certification = [
  PCI,
  IATA,
  visa,
  MasterCard,
  express,
  DISCOVER,
  PayPal
]

const Footer: React.FC = () => {
  const matches = useMediaQuery('(max-width:1000px)');
  const { userLocation } = useSelector((state: any) => state.userReducer)
  const { currency } = useSelector((state: any) => state.appReducer)
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div className="sm:w-[20%] flex flex-col sm:mt-2 mt-2">
        <h2 className="font-semibold text-white sm:text-xl text-lg text-center sm:text-left">
          {menu.title}
        </h2>
        <ul className={`${menu.title === "" ? "font-semibold text-white sm:text-xl text-lg text-center sm:text-left" : "sm:space-y-1 space-y-1"}`}>
          {menu.menus.map((item, index) => (
            <li key={index} className="max-h-fit sm:text-left text-center">
              <Link
                to={item.href}
                state={{ data: item.data }}
                className="text-white text-sm sm:text-left text-center">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div >
    );
  };

  return (
    <div className="footer-background">
      <div className="w-[92%] py-8 mx-auto flex gap-1 flex-col sm:flex-row h-fit justify-between items-center sm:items-start">

        {/* column 1 */}
        <div className="sm:w-[40%] w-[60%] flex flex-col sm:gap-4 gap-2 justify-start">
          <div className="logo-container sm:w-fit sm:justify-start justify-center">
            <img src={logo} className="lg:w-[60%] " alt="" />
          </div>
          <div className="flex items-center sm:gap-2 gap-[5%] sm:pl-6 sm:justify-start justify-center">
            <TiContacts className="text-3xl text-white w-[25%] sm:w-fit" />
            <div className="flex flex-col justify-center sm:w-fit w-[70%]">
              <p className="text-[11px] text-white">24/7 Helpline</p>
              <a
                onClick={() =>
                  gtag_report_conversion(`tel:${currency?.country === "IN" ? "+1 8033692136 " : '+1 8033692136'}`)
                }
                className='text-[16px] text-white font-medium' href={`tel:${currency?.country === "IN" ? "+91 011 420 77777 " : '+1 8033692136'}`}>
                {currency?.country === "IN" ? (<span>011 42077777</span>) : (<span>+1&nbsp;801&nbsp;882&nbsp;7740</span>)}
              </a>
            </div>
          </div>
          <div className="flex items-center sm:gap-2 gap-[5%] sm:pl-6 sm:justify-start justify-center">
            <AiOutlineMail className="text-3xl text-white w-[25%] sm:w-fit" />
            <div className="flex flex-col justify-center sm:w-fit w-[70%]">
              <p className="text-[11px] text-white">Email</p>
              <h4 className="text-[16px] text-white font-medium"></h4>
            </div>
          </div>
          {/* social icons */}
          <SocialsList1 className="" />
        </div>
        {/* column1 end */}

        {/* column2 */}

        {widgetMenus.map(renderWidgetMenuItem)}
      </div>


      <div className="w-full bg-white py-[20px]">
        {matches ?
          <div className="w-[95%] flex flex-col gap-4 items-center">
            <div className="font-semibold sm:text-sm text-xs">© 2021-2024 Airtickkets . All Rights Reserved.</div>
            <div className="flex flex-wrap sm:gap-5 gap-2 justify-center ">
              {certification.map((item: any) => {
                return <div className="w-[60px] h-[35px]">
                  <img src={item} alt="" className="w-full h-full object-contain" />
                </div>
              })}
            </div>
          </div>
          :
          <div className="sm:w-[92%] w-[95%] h-full mx-auto flex items-center justify-between">
            <div className="font-semibold sm:text-sm text-xs">© 2021-2024 Airtickkets. All Rights Reserved.</div>
            <div className="flex flex-wrap sm:gap-5 gap-2 justify-end ">
              {certification.map((item: any) => {
                return <div className="w-[60px] h-[35px]">
                  <img src={item} alt="" className="w-full h-full object-contain" />
                </div>
              })}
            </div>
          </div>
        }
      </div>

    </div>
  );
};

export default Footer;
