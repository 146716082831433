
import React from "react";
import { BsCalendar3 } from "react-icons/bs"
import { TbArrowsRightLeft } from "react-icons/tb"
import { AiOutlineClose } from "react-icons/ai";
import "./flightsearchpopup.css"
import { useSelector } from "react-redux";
import { JourneyType } from "utils/enums";
import Loading from "shared/Loading/Loading";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { IoCallSharp } from "react-icons/io5"
import OutsideClickHandler from "utils/OutsideClickHandler";
import { gtag_report_conversion } from "googleAnalytics/googleAds";
import { callNowButtonTags } from "googleAnalytics/callNowButtonTags";
interface PopupProps {
    onClose?: any,
}
const FlightSearchPopup = ({ onClose }: PopupProps) => {
    const { flights } = useSelector((state: any) => state.flightsReducer)
    const { currency, forexValue } = useSelector((state: any) => state.appReducer)
    const { userLocation } = useSelector((state: any) => state.userReducer)
    const [urlSearchParams]: any = useSearchParams()


    const [minPriceFlight, setMinPriceFlight] = React.useState<any>(null)

    //urlSearchParams.get('triptype') === JourneyType.OneWay

    function getFlightData() {
        if (flights.length === 0) return
        if (urlSearchParams.get('triptype') === JourneyType.OneWay || urlSearchParams.get('triptype') === JourneyType.MultiStop) {
            let minPrice1 = flights?.map((flight: any) => flight?.reduce((prev: any, curr: any) => {
                return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
            }));
            let minPriceFlight = minPrice1?.reduce((prev: any, curr: any) => {
                return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
            }, {})
            setMinPriceFlight([minPriceFlight])
        } else {
            if (flights.length > 1) {
                let minPrice1 = flights[0]?.reduce((prev: any, curr: any) => {
                    return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
                });
                // let minPriceFlight1 = minPrice1?.reduce((prev: any, curr: any) => {
                //     return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
                // }, {})

                let minPrice2 = flights[1]?.reduce((prev: any, curr: any) => {
                    return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
                });
                // let minPriceFlight2 = minPrice2?.reduce((prev: any, curr: any) => {
                //     return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
                // }, {})
                setMinPriceFlight([minPrice1, minPrice2])
            } else {
                let minPrice1 = flights?.map((flight: any) => flight?.reduce((prev: any, curr: any) => {
                    return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
                }));
                let minPriceFlight = minPrice1?.reduce((prev: any, curr: any) => {
                    return prev?.Fare?.PublishedFare < curr?.Fare?.PublishedFare ? prev : curr;
                }, {})
                setMinPriceFlight([minPriceFlight])
            }
        }
    }

    React.useEffect(() => {
        if (flights) {
            getFlightData()
        }
    }, [flights])

    function getDestination(minPriceFlight: any) {
        return <>
            <div className="city-code text-2xl font-bold">{urlSearchParams.get('to') || urlSearchParams.get('to1')}</div>
            {/* <div className="city text-xs">{minPriceFlight?.Segments[0][minPriceFlight?.Segments[0]?.length - 1]?.Destination?.Airport?.CityName || minPriceFlight?.Segments[0][minPriceFlight?.Segments[0]?.length - 1]?.Origin?.Airport?.AirportName}</div> */}
        </>
    }


    if (!minPriceFlight) return (<>
        <div onClick={onClose} className="absolute top-[10px] right-[10px] text-[25px] p-[10px] cursor-pointer bg-white rounded-full"><AiOutlineClose /></div>
        <Loading></Loading>
    </>);

    function renderPrice(flight: any) {
        if (urlSearchParams.get('triptype') === JourneyType.OneWay || urlSearchParams.get('triptype') === JourneyType.MultiStop) {
            return <div className="price text-2xl font-bold">{currency?.symbol}{parseFloat((forexValue * (flight[0].Fare.PublishedFare)).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}*</div>
        } else {
            if (flight.length > 1) {
                return <>
                    <div className="price text-lg font-bold">
                        <span className="text-sm text-gray-500">For ({urlSearchParams.get('from1')}) to ({urlSearchParams.get('to1')})</span>  {currency?.symbol}{parseFloat((forexValue * (flight[0].Fare.PublishedFare)).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}*
                    </div>
                    <div className="price text-lg font-bold">
                        <span className="text-sm text-gray-500">For ({urlSearchParams.get('from2')}) to ({urlSearchParams.get('to2')})</span>  {currency?.symbol}{parseFloat((forexValue * (flight[1].Fare.PublishedFare)).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}*
                    </div>
                </>
            } else {
                return <div className="price text-2xl font-bold">{currency?.symbol}{parseFloat((forexValue * (flight[0].Fare.PublishedFare)).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <span className="text-[#F70F36]">*</span></div>
            }
        }
    }

    return (
        <>
            <OutsideClickHandler
                onClick={onClose}
            >
                <div className=" overflow-hidden relative rounded-lg min-w-[300px] max-w-[500px] bg-white p-[30px] flex flex-col items-center">
                    {/* close button */}
                    <div onClick={onClose} className="absolute top-[10px] right-[10px] text-[25px] p-[10px] cursor-pointer"><AiOutlineClose /></div>

                    <div className="py-[5px] font-semibold text-2xl text-[#F70F36]">Limited time offer!</div>
                    {/* limited offer time */}
                    {/* <div className="py-[5px] font-bold flex justify-center items-center text-2xl">
                    <div> 14m 21s</div>
                </div> */}

                    {/* <div className="font-semibold">Special Fare to Las Vegas</div> */}



                    <div className="mt-[20px] mb-[10px] p-[6px] flex justify-between items-center w-[100%] border-2 border-orange-500 border-solid rounded-lg">
                        {/* origin */}
                        <div className="flex flex-col items-start w-[30%]">
                            <div className="city-code text-2xl font-bold">{urlSearchParams.get('from') || urlSearchParams.get('from1') || minPriceFlight?.Segments[0][0]?.Origin?.Airport?.CityCode || minPriceFlight?.Segments[0][0]?.Origin?.Airport?.AirportCode}</div>
                            {/* <div className="city text-xs">{minPriceFlight?.Segments[0][0]?.Origin?.Airport?.CityName || minPriceFlight?.Segments[0][0]?.Origin?.Airport?.AirportName}</div> */}
                        </div>
                        <div className="text-4xl"><TbArrowsRightLeft /></div>
                        {/* destination */}
                        <div className="flex flex-col items-end w-[30%]">
                            {getDestination(minPriceFlight)}

                        </div>
                    </div>
                    <div className={`py-[5px] flex ${urlSearchParams.get('triptype') !== JourneyType.Return ? "justify-center" : "justify-between"} w-[100%]`}>
                        <div className="depart w-1/3">
                            <div className={`flex items-center ${urlSearchParams.get('triptype') !== JourneyType.Return ? "justify-center" : "justify-start"}`}>
                                <div className="text-[#F70F36]"><BsCalendar3 /></div>
                                <div className="font-semibold p-[2px]">DEPART</div>
                            </div>
                            <div className={`flex items-start ${urlSearchParams.get('triptype') !== JourneyType.Return ? "justify-center" : "justify-start"}`}>
                                {/* depart date */}
                                <div className="date text-2xl font-bold">
                                    {moment(new Date(urlSearchParams.get('depart'))).format('DD')}
                                </div>
                                <div className="month-year-date flex flex-col items-start">
                                    <div className="px-[5px] font-bold flex flex-row flex-nowrap">
                                        <div className="mr-1">{moment(new Date(urlSearchParams.get('depart'))).format('MMM')}</div> <div>{moment(new Date(urlSearchParams.get('depart'))).format('YYYY')}</div>
                                    </div>
                                    <div className="px-[5px]">{moment(new Date(urlSearchParams.get('depart'))).format('dddd')}</div>
                                </div>
                            </div>
                        </div>
                        {urlSearchParams.get('triptype') === JourneyType.Return && <div className="return w-1/3">
                            <div className="flex items-center justify-end">
                                <div className="text-[#F70F36]"><BsCalendar3 /></div>
                                <div className="font-semibold p-[2px]">RETURN</div>
                            </div>
                            <div className="flex items-start justify-end">
                                {/* return date */}
                                <div className="date text-2xl font-bold"> {moment(new Date(urlSearchParams.get('return'))).format('DD')}</div>
                                <div className="month-year-date flex flex-col items-end">
                                    <div className="px-[5px] font-bold flex flex-row flex-nowrap">
                                        <div className="mr-1">{moment(new Date(urlSearchParams.get('return'))).format('MMM')}</div>
                                        <div>{moment(new Date(urlSearchParams.get('return'))).format('YYYY')}</div>
                                    </div>
                                    <div className="px-[5px]">{moment(new Date(urlSearchParams.get('return'))).format('dddd')}</div>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="py-[10px] border-b border-black w-[100%] border-dotted"></div>
                    <div className="py-[5px] flex justify-between w-full">
                        <div className="trip-class">
                            {/* trip and class type */}
                            <div className="trip font-bold">{urlSearchParams.get('triptype') === JourneyType.OneWay ? "One-Way" : (urlSearchParams.get('triptype') === JourneyType.Return ? "Round-Trip" : "Multi-City")}</div>
                            <div className="class"> {(() => {
                                switch (urlSearchParams.get('class')) {
                                    case "2":
                                        return "Economy"
                                    case "3":
                                        return "Premium Economy"
                                    case '4':
                                        return "Business"
                                    case '6':
                                        return "First"
                                }
                            })()}
                            </div>
                        </div>
                        <div className="price flex flex-col items-end text-sm">
                            {/* price */}
                            {renderPrice(minPriceFlight)}
                        </div>
                    </div>
                    <div className="my-[10px]">
                        <a
                            onClick={() => {
                                gtag_report_conversion(`tel:${currency?.country === "IN" ? "+91 11 420 77777 " : '+1 8033692136'}`)
                                callNowButtonTags(`tel:${currency?.country === "IN" ? "+91 11 420 77777 " : '+1 8033692136'}`)
                            }
                            }
                            href={`tel:${currency?.country === "IN" ? "+91 011 420 77777 " : '+1 8033692136'}`} className="my-[5px] cursor-pointer bg-[#F70F36] rounded-md py-[10px] px-[35px] text-white button flex items-center">
                            <IoCallSharp /> {currency?.country === "IN" ? "011 42077777" : "+1 8033692136"}
                        </a>
                    </div>
                    <div className="py-[5px] text-[12px] flex flex-col justify-center items-center">
                        <div><span className="text-[#F70F36]">*</span> Fares are Subject to Seat Availability & Not Guaranteed Until Ticketed</div>
                    </div>
                    {/* Bg Image */}
                    {/* <img src={BgImage} className="absolute h-full top-0 rounded-2 z-[-1]" alt="" /> */}
                </div>
            </OutsideClickHandler>
        </>
    );
}
export default FlightSearchPopup;