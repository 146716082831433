import React from 'react'
import image from "../../images/about2.png"

const steps = [
    { title: 'Book flight in advance :', content: "With the right planning and the optimum use of time, you can avail a good deal on airline tickets. All you need to do is to book flights well in advance (at least 30 days in advance). With your departure date coming nearer, the airfare tends to rise." },
    { title: 'Go for budget airlines :', content: "Thanks to the increasing number of budget airlines, you can now travel to your favorite destination for cheap. If you are not a luxury-seeking traveler, flying in budget airlines is the right option for you." },
    { title: 'Use reward points :', content: "Redeeming reward points can make your flight cheap.What can be more gratifying than paying for your airfare with points you have earned on your previous journeys. You just need to sign up for an airline reward program and earn points each time you travel." },
    { title: 'Speak to a travel agent :', content: "It's true that online flight deals can get you a cheap flight, but there is also a high chance that a travel agent can find a better deal for you. Call center representatives have options to waive a few charges and customize the deal. All you need to do is to be patient while calling and you may end up getting an enticing airfare deal." },
    { title: 'Be flexible with travel plans :', content: "You never know you can get better deals on online flight booking if you search for different dates. You can also avail better deals if you are flexible to change your destination. For instance, if you want to spend a holiday amidst serenity, you can choose another mountainous or beach destination in place of the one that has a high-ticket price." },
    { title: 'Join a frequent flyer program :', content: "Several airlines run their frequent flyer program in which, you can earn points that can make you get cheaper tickets and upgrades. What's more! You can join these frequent flyer programs for free. You must read the rules and regulations carefully before joining any frequent flyer program." },
    { title: 'Subscribe to email notifications : ', content: "Reading every email that lands in your inbox is certainly a tiring job. However, reading the mail that talks about saving your hard-earned money on flight tickets can be worthwhile. Through subscribing to email notifications and newsletters, you can get frequent information on deals and book accordingly." },
    { title: 'Try paying in a different currency : ', content: "It is a hack used by very few. Many a time, you can get tickets cheaply just by paying the airfare in a different currency. You should try paying in a different currency in which you find the tickets cheap." },
    { title: 'Book with different airlines : ', content: "Return flights are undoubtedly convenient and cheaper options. But there are chances that if you search for flights offered by other airlines- and be a tad flexible about the time- you can get a low-fare flight." },
]
const destinations = [
    'Orlando',
    'Beijing',
    'Bogota',
    'Rio de Janeiro',
    'Cancun',
    'Montenegro',
    'Bali',
    'Budapest',
    'Costa Rica',
    'Georgia',
    'Hanoi',
    'Athens',
]

const HomePageContent = () => {
    return (
        <div className='pb-16 mt-8 text-justify container'>
            <div className='flex flex-col lg:flex-row'>
                <div className="xl:w-[55%] lg:w-[55%]">
                {/* <div className="xl:w-[65%] lg:w-[50%]"> */}
                    <h1 className='text-2xl font-semibold'>Book Cheap Flights on airtickkets</h1>
                    <p>We always love to explore and book cheap flights to our favorite destination and 2022 is no different and with airtickkets you can explore numerous cheap flights to thousands of destinations. As travel restrictions ease-out, more people will be traveling to meet their families and to satiate their travel quest.</p><br />
                    <p>Whether you are traveling for work or to explore and learn or to reunite with family and friends, or simply just seeking solitude, airtickkets is here to fulfill all your travel needs.</p><br />
                    <p>
                        Doesn’t matter which destination you choose for your next expedition, airtickkets is here to assist and ensure you get a good flight deal. We understand the importance of accommodating our customer's last-minute travel needs and requirements, which is why you can call us anytime, from anywhere to know more about available deals and book your flight.
                    </p><br />
                    <p>You can also book your flight tickets from anywhere through our state-of-the-art mobile apps. Our cutting-edge technology and user-friendly interface ensure a hassle-free online booking experience at every step. We make affordable travel accessible on all Android and Apple devices and offer dedicated customer support around the clock. From searching for last minute flights to planning vacations, you can count on us to make travel bookings easy!</p>
                </div>
                <div className='hidden lg:flex w-[45%] mx-auto mt-4 lg:ml-auto lg:mt-0  justify-center'>
                    <img src={image} alt="About" className='lg:w-[85%] xl:w-[80%] 2xl:h-[100%] xl:h-[95%] lg:h-[70%] sm:h-[40%] h-[40%] ' />
                    {/* <img src={image} alt="About" className='xl:w-[80%] xl:h-[80%] lg:h-[70%] sm:h-[40%] h-[40%] ' /> */}

                </div>
            </div>
            <br />
            <div>
                <h1 className='text-2xl font-semibold my-[15px] sm:my-auto'>How to find cheap flight deals?</h1>
                <ol className='list-decimal list-inside'>
                    {steps.map((item: any, idx: any) => {
                        return <li className='font-semibold my-2'>{item?.title} <span className='font-normal'>{item.content}</span></li>
                    })}
                </ol>
            </div>
            <div>
                <h1 className='text-2xl font-semibold  my-[15px] sm:my-auto'>What are the recommended days to fly in order to get cheap flights?</h1>
                <p>There’s no definitive rule for which days are best to fly for cheap flights to all destinations. Every airport and place is a little different and has their own specific days and times when there are less travellers’ usually flying in and out, creating a higher surplus of available seats with less demand and causing airlines to charge less. There’s also seasonal and holiday travel, which creates more demand and lowers any chance of cheap flights. But there is a general pattern in how most people travel that you can take advantage of to save money on airline tickets. Most people will book to fly on Friday and the weekend and that’s why flights on those days are generally the most expensive. So, just being willing to fly Monday through Thursday will increase the likelihood of snagging cheap flights.
                </p>
            </div>
            <br />
            <div>
                <h1 className='text-2xl font-semibold my-[15px] sm:my-auto'>What are the pocket friendly months to book plane tickets?</h1>
                <p>The answer totally depends on the destination you are traveling to. During the peak tourism season of any destination, the ticket prices are on the higher side. Off season is the ideal time to travel however, you must ensure the climate condition must be suitable enough for the journey.
                </p>
            </div>
            <br />
            <div>
                <h1 className='text-2xl font-semibold my-[15px] sm:my-auto'>How far in advance should I book a flight to get good deals?</h1>
                <p>In general, you should book tickets at least 30 days in advance to get a cost-effective ticket. If you are planning to visit during the peak season, it is advisable to book tickets 45-60 days in advance as airfare tends to rise with the ticking of the clock.
                </p>
            </div>
            <br />
            <div>
                <h1 className='text-2xl font-semibold my-[15px] sm:my-auto'>Which are the top budget travel destinations for 2023?</h1>
                <div className='flex flex-col'>
                    {destinations.map((dest: any) => <p>{dest}</p>)}
                </div>
            </div>
            <br />
            <div>
                <h1 className='text-2xl font-semibold my-[15px] sm:my-auto'>How to search for cheap flights right now?</h1>
                <p>Have to fly due to any urgency and looking for cheap flights right now? airtickkets brings you cheap last-minute flights to tourist destinations around the world. Avail these last-minute flight deals and fly to your desired destination for cheap. With ample deals available to pick from, you can choose the one that suits you the best and travel without worrying about the budget. We keep on updating the airfares and offer you with numerous flight deals and information on the cheap flights right now to anywhere in the world. Browse from the extensive list of flights or call us anytime round the clock and ensure huge savings!</p>
            </div>
        </div>
    )
}

export default HomePageContent