import React, { useEffect, useState } from 'react'
import "./BestOffers.css"
import { doc, getDoc } from 'firebase/firestore'
import { db } from 'config/firebase-config'
import { gtag_report_conversion } from "./../../../../googleAnalytics/googleAds"
import { useDispatch, useSelector } from "react-redux";




const BestOffers = () => {
    const { currency } = useSelector((state: any) => state.appReducer)
    const [data, setData] = useState<any>([])


    const getoffersData = async () => {
        let data2: any
        let documentSnapshots;
        documentSnapshots = await getDoc(
            doc(db, "Offers", "best-offers")
        );
        // console.log(documentSnapshots.data(), "data ")
        data2 = documentSnapshots.data()
        // console.log(data2.bestOffers, "data from bext offers")
        setData(data2.bestOffers)


    }

    useEffect(() => {
        getoffersData()
    }, [])

    return (
        <div className="w-full mt-[-10px] p-[20px] px-[3.5%] flex box-border flex-col relative z-[9] ">
            <div className="p-[10px] box-border">
                <h1 className="lg:text-[38px] md:text-[30px] sm:text-[28px] text-[26px] font-semibold my-[13px] text-center z-20">Best Offers</h1>
                <h3 className='lg:text-[18px] md:text-[18px] sm:text-[15px] text-[18px]  my-[13px] text-center z-20 text-[#aaaaaaff]'>Take a Break with Savings</h3>
            </div>
            <div className="p-[10px] w-full best-cont m-auto ">
                {data.length > 0 &&
                    data?.map((item: any, idx: any) => {
                        return <div className='relative ' key={idx} >
                            <img className='relative w-full h-full top-0 z-10 rounded-2xl ' loading='lazy' src={item.imgUrl} alt="" />
                            <div className='dest absolute top-0 z-30 flex items-end p-[20px] w-full h-full  rounded-2xl'>
                                <div className="text-white flex flex-col w-full h-full  justify-between pt-[10px] ">
                                    <div className='text-bold text-[18px]'>
                                        <h1 className="font-bold text-[#fff] md:text-[20px] text-[18px] sm:text-[15px]">{item.heading1}</h1>
                                        <h1 className="font-bold text-[#fff] md:text-[20px] text-[18px] sm:text-[15px]">{item.heading2}</h1>
                                        <p className=" text-[#fff] md:text-[15px] text-[15px] sm:[12px] my-[10px]"> {item.description}</p>

                                        <a
                                            href={`tel:${currency?.country === "IN" ? "+91 011 420 77777 " : '+1 8033692136'}`}>
                                            <div onClick={() => {
                                                gtag_report_conversion(`tel:${currency.country === "IN" ? "+91 11 420 77777" : "+1 8033692136"}`)

                                            }}>
                                                <div className='cursor-pointer w-fit text-white px-[10px] py-[5px] bg-[#F70F36] hover:bg-red-700 rounded-md sm:text-[15px] text-[12px]'
                                                >
                                                    CALL NOW
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div >
    )
}

export default BestOffers